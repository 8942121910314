import { RefObject, useCallback, useEffect, useRef } from "react";

const useClickOutside = <T extends HTMLElement>(ref: RefObject<T>, callback: () => void, circuitBreaker = false) => {
	const callbackRef = useRef<() => void>();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!circuitBreaker && ref.current && !ref.current.contains(event.target as Node)) {
				callbackRef.current?.();
			}
		},
		[circuitBreaker, ref],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);
};

export default useClickOutside;
