import {
	Axs,
	FanXchange,
	GameTime,
	SeatGeek,
	StubHub,
	StubHubInt,
	TicketEvolution,
	TicketNetwork,
	TicketsNow,
	TickPick,
	VividSeats,
} from "@src/assets/martkeplace-icons";
import React from "react";

export interface ITransformedMarkups {
	id: number;
	icon: React.JSX.Element;
}

const populateIcons = (id: number) => {
	switch (id) {
		case 104:
			return <StubHub />;
		case 105:
			return <TicketNetwork />;
		case 106:
			return <TicketsNow />;
		case 115:
			return <VividSeats />;
		case 116:
			return <TicketEvolution />;
		case 117:
			return <SeatGeek />;
		case 118:
			return <TickPick />;
		case 120:
			return <div>VG</div>;
		case 121:
			return <GameTime />;
		case 122:
			return <FanXchange />;
		case 125:
			return <Axs />;
		case 126:
			return <StubHubInt />;
		default:
			return <div>{id}</div>;
	}
};

export const transformMarkups = (data: Array<number>) => {
	return data.map((id) => ({ id, icon: populateIcons(id) }));
};
