import moment from "moment";
import { TDateOrNull } from "../input/DateInput";

export const formatDateToLocale = (dateString: string | Date | undefined) => {
	if (!dateString) return "No date";
	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		return "Failed date format";
	}

	const newDate = date.toLocaleDateString("en-US", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});

	return newDate;
};

export const enum DateFormatsEnum {
	"MM/DD/YYYY" = "MM/DD/YYYY",
	"DD MMM" = "DD MMM",
	"MM/DD/YYYY mm:ss" = "MM/DD/YYYY mm:ss",
	"MM/DD/YYYY | mm:ss a" = "MM/DD/YYYY | mm:ss a",
	"mm:ss" = "mm:ss",
	"dddd" = "dddd",
	"HH:MM AM/PM" = "hh:mm A",
}
export const formatDate = (
	dateString: string | Date | undefined | null,
	format: DateFormatsEnum = DateFormatsEnum["MM/DD/YYYY"],
) => {
	if (!dateString) return "--";
	return moment(dateString).format(format);
};

export const daysDifference = (targetDate: Date) => {
	const currentDate = new Date();
	const target = new Date(targetDate);

	const differenceInTime = target.getTime() - currentDate.getTime();
	return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
};

export const getDatePickerFormattedDate = (date: TDateOrNull, daysOffset = 0) => {
	if (!date) return "";

	const newDate = new Date(date?.getFullYear(), date?.getMonth(), date?.getDate() + daysOffset);
	return `${newDate?.getMonth() + 1}-${newDate?.getDate()}-${newDate?.getFullYear()}`;
};
