import React from "react";
import styles from "./ApStatus.module.scss";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { useAppDispatch } from "@src/store/hooks";
import { eventActions, settingsActions } from "@src/store";
import { externalAutoPricerRowClick } from "../Inventory";

interface IApStatusType {
	row: EventInventoryRecord;
}

export const ApStatus: React.FC<IApStatusType> = ({ row }) => {
	const dispatch = useAppDispatch();

	const options = [
		{ text: "Hit Ceiling", color: "#54C339" },
		{ text: "Hit Floor", color: "#FF2D5F" },
		{ text: "Active", color: "#3F7BF3" },
		{ text: "", color: "" },
	];

	const isSold = row.Invoice_ID;

	let item = options[3];
	const hasActiveCriteria = row.Is_Error || row.Is_No_Comparables || row.Is_AutoPriced;

	if (hasActiveCriteria && !row.Is_At_Ceiling && !row.Is_At_Ceiling) item = options[2];
	if (row.Is_At_Floor) item = options[1];
	if (row.Is_At_Ceiling) item = options[0];

	const handleFieldClick = () => {
		if (isSold) return;

		const config = { inventoryItemIds: [row.Item_ID] };
		dispatch(eventActions.setInventory(config));
		dispatch(settingsActions.setAutoPricerPopup(true));
		externalAutoPricerRowClick(row);
	};

	if (isSold) return null;

	return (
		<div onClick={handleFieldClick} aria-hidden="true" className={styles.main} style={{ color: item.color }}>
			{item.text ? <div className={styles.circle} /> : null}
			<div className={styles.text}>{item.text}</div>
		</div>
	);
};
