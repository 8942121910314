import { XIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { ChooseRule } from "@src/components/chooseRule/ChooseRule";
import { Loader } from "@src/components/loader/Loader";
import { MarketPlaces } from "@src/components/marketPlaces/MarketPlaces";
import { Splits } from "@src/components/splits/Splits";
import { Switch } from "@src/components/switch/Switch";
import { VGrid } from "@src/components/vGrid/VGrid";
import { pricingActions } from "@src/store";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { ISavedRules } from "@src/store/pricing/savedRulesSlice";
import React, { useEffect } from "react";
import manageListingsStyles from "../../manage-inventory/manage-listing/ManageListing.module.scss";
import { columns } from "./columns";
import styles from "./SharingSettings.module.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

interface ISharingSettings {
	handleClose?: () => void;
	rowsData: Array<EventInventoryRecord>;
}

export const SharingSettings: React.FC<ISharingSettings> = ({ rowsData, handleClose }) => {
	const { data, loading } = useAppSelector((state) => state.pricing.sharingSettings);
	const { loading: applyLoading } = useAppSelector((state) => state.pricing.listingsSharing);
	const dispatch = useAppDispatch();
	const handleSwitchChange = () => dispatch(pricingActions.toggleVisibility());

	const onRulePick = (data: ISavedRules) => {
		const allMarkups = data.Markups.map((x) => x.Export_Destination_ID);
		dispatch(pricingActions.setSavedRuleIds(allMarkups));
	};

	const ListingIds = rowsData.map((x) => x.Listing_ID);

	const onApply = () => {
		const payload = {
			ListingIds: ListingIds,
			ShareOutSettings: data.ids.map((x) => ({
				Export_Destination_ID: x,
				AllowedSplits: data.allowedSplits,
				Visibility: data.visibility ? ("HIDE" as const) : ("SHOW" as const),
			})),
		};
		// Just uncomment this line to check out the feature
		dispatch(pricingActions.updateSharing(payload));
	};

	const onRuleSave = (RuleName: string) => {
		const { allowedSplits, visibility } = data;
		const Markups = data.ids.map((x) => ({ Export_Destination_ID: x }));
		const Visibility = visibility === true ? "HIDE" : "SHOW";
		const payload = {
			AllowedSplits: allowedSplits,
			Visibility,
			Markups,
			RuleName,
		};

		dispatch(pricingActions.saveRule(payload));
	};

	const splitProps = {
		allowedSplits: data.allowedSplits,
	};

	useEffect(() => {
		dispatch(pricingActions.getListingSharedSettings([rowsData[0].Listing_ID]));
	}, []);

	return (
		<div className={styles.sharingSettings}>
			<div className={styles.sharingHeadline}>
				<div>Sharing Settings</div>
				<div aria-hidden="true" style={{ cursor: "pointer" }} onClick={handleClose}>
					<XIcon />
				</div>
			</div>
			<div className={styles.sharingContent}>
				<div className={styles.exportDestinations}>
					<ChooseRule onRuleSave={onRuleSave} onRulePick={onRulePick} />
					<div className={styles.headlineText}>Export Destinations</div>
					{loading ? (
						<div className={styles.marketplacesLoadingContainer}>
							<Loader />
						</div>
					) : (
						<React.Fragment>
							<MarketPlaces items={data.ids} wrapperStyle={{ paddingTop: "10px" }} />
							<div className={styles.splits}>
								<div className={styles.headlineText}>Splits</div>
								<div className={manageListingsStyles.splitsContainer}>
									<Splits {...splitProps} wrapperStyle={{ paddingTop: "10px" }} />
									<div className={manageListingsStyles.switchContainer}>
										<Switch label="Hide Seats" checked={data.visibility} onChange={handleSwitchChange} />
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				<div className={styles.table}>
					<div className={styles.headlineText} style={{ padding: "8px" }}>
						Current Settings
					</div>
					<div className={styles.tableContainer}>
						<VGrid<EventInventoryRecord> rowsData={rowsData} columns={columns} getRowNodeId={(x) => x.Event_ID} />
					</div>
				</div>
				<div className={styles.buttons}>
					<Button onClick={handleClose} style={{ minWidth: "100px", fontSize: "14px" }} variant={ButtonVariant.Tertiary}>
						Cancel
					</Button>
					<Button style={{ minWidth: "100px", fontSize: "14px" }} variant={ButtonVariant.Tertiary}>
						Share & Save
					</Button>
					<Button
						onClick={onApply}
						isLoading={applyLoading}
						disabled={applyLoading}
						style={{ minWidth: "100px", fontSize: "14px" }}
						variant={ButtonVariant.Primary}>
						Share
					</Button>
				</div>
			</div>
		</div>
	);
};
