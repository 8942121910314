import { GridSection } from "@src/components/filtersModal/GridSection";
import { DateInput } from "@src/components/input/DateInput";
import { Input } from "@src/components/input/Input";
import { SelectYup } from "@src/components/select/SelectYup";
import { SalesFiltersProps } from "@src/validation/SalesHeaderFiltersValidation";
import React from "react";
import { Control, UseFormReset, UseFormSetValue } from "react-hook-form";
import styles from "./SalesFilters.module.scss";

interface ICategoriesGeneric {
	label: string;
	value: string | number;
}

interface IAllOptions {
	categoryOptions: Array<ICategoriesGeneric>;
	headlinerOptions: Array<ICategoriesGeneric>;
	venueOptions: Array<ICategoriesGeneric>;
	marketplaceOptions: Array<ICategoriesGeneric>;
	saleStatusesOptions: Array<ICategoriesGeneric>;
	tagOptions: Array<ICategoriesGeneric>;
}

interface IAllFilters extends IAllOptions {
	control: Control;
	setValue: UseFormSetValue<SalesFiltersProps>;
	reset: UseFormReset<SalesFiltersProps>;
}

export const SalesFilters: React.FC<IAllFilters> = (props) => {
	const { control, categoryOptions, headlinerOptions, venueOptions, tagOptions, marketplaceOptions, saleStatusesOptions } =
		props;

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.inner}>
					<GridSection headline="Event">
						<SelectYup name="tags" placeholder="Tags" control={control} options={tagOptions} />
						<SelectYup name="category" placeholder="Category" control={control} options={categoryOptions} />
						<div className={styles.subItems}>
							<DateInput control={control} name="eventFromDate" placeholder="Event Start" />
							<DateInput control={control} name="eventToDate" placeholder="Event End" />
						</div>
					</GridSection>
					<GridSection>
						<SelectYup name="headliner" placeholder="Headliner" control={control} options={headlinerOptions} />
						<SelectYup name="venue" placeholder="Venue" control={control} options={venueOptions} />
					</GridSection>

					<GridSection headline="Sales">
						<SelectYup
							name="exchangeName"
							placeholder="Choose Marketplace"
							control={control}
							options={marketplaceOptions}
						/>
						<div className={styles.subItems}>
							<DateInput control={control} name="paymentFromDate" placeholder="Payment Start" />
							<DateInput control={control} name="paymentToDate" placeholder="Payment End" />
						</div>
						<SelectYup
							name="saleStatus"
							placeholder="Choose Sales Status"
							control={control}
							options={saleStatusesOptions}
						/>
						<div className={styles.subItems}>
							<DateInput control={control} name="saleFromDate" placeholder="Sales Start" />
							<DateInput control={control} name="saleToDate" placeholder="Sales End" />
						</div>
					</GridSection>

					<GridSection headline="Ticket">
						<Input control={control} name="sections" placeholder="Section" />
						<Input control={control} name="rows" placeholder="Row" />
						<Input control={control} type="number" name="minQuantity" placeholder="Min. Quantity" />
					</GridSection>

					<GridSection headline="Advanced">
						<Input name="invoiceId" placeholder="Invoice ID" control={control} />
						<Input name="eventId" placeholder="Event ID" control={control} />
						<Input name="buyerPOId" placeholder="Buyer PO ID" control={control} />
					</GridSection>
				</div>
			</div>
		</div>
	);
};
