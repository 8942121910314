// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Checkbox-module_main___YhVyK {
  border: 1px solid #8a8c91;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  box-shadow: none;
}
.Checkbox-module_main___YhVyK[disabled] {
  background-color: #ebebeb;
  cursor: not-allowed;
}
.Checkbox-module_main___YhVyK.Checkbox-module_sm___Zk66I {
  width: 11px !important;
  height: 11px !important;
  border-radius: 2px;
  font-size: 11px;
}
.Checkbox-module_main___YhVyK.Checkbox-module_m___lPYgo {
  width: 14px !important;
  height: 14px !important;
  border-radius: 2px;
  font-size: 10px;
}
.Checkbox-module_main___YhVyK.Checkbox-module_l___Lgpyj {
  width: 19px !important;
  height: 19px !important;
  border-radius: 2px;
  font-size: 12px;
}
.Checkbox-module_main___YhVyK:checked {
  accent-color: #96bf7a;
  background-color: #96bf7a;
  border: 1px solid #4e9d3a !important;
}
.Checkbox-module_main___YhVyK:checked:after {
  content: "✓";
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/vGrid/Checkbox.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAGhB;EACC,yBAAA;EAuCA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,gBAAA;EACA,gBAAA;AAxCD;AAFC;EACC,yBAAA;EACA,mBAAA;AAIF;AADC;EACC,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AAGF;AAAC;EACC,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AAEF;AACC;EACC,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AACF;AAEC;EACC,qBAjCkB;EAkClB,yBAlCkB;EAmClB,oCAAA;AAAF;AAEE;EACC,YAAA;EACA,YAAA;AAAH","sourcesContent":["@charset \"UTF-8\";\n.main {\n  border: 1px solid #8a8c91;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  appearance: none;\n  box-shadow: none;\n}\n.main[disabled] {\n  background-color: #ebebeb;\n  cursor: not-allowed;\n}\n.main.sm {\n  width: 11px !important;\n  height: 11px !important;\n  border-radius: 2px;\n  font-size: 11px;\n}\n.main.m {\n  width: 14px !important;\n  height: 14px !important;\n  border-radius: 2px;\n  font-size: 10px;\n}\n.main.l {\n  width: 19px !important;\n  height: 19px !important;\n  border-radius: 2px;\n  font-size: 12px;\n}\n.main:checked {\n  accent-color: #96bf7a;\n  background-color: #96bf7a;\n  border: 1px solid #4e9d3a !important;\n}\n.main:checked:after {\n  content: \"✓\";\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Checkbox-module_main___YhVyK`,
	"sm": `Checkbox-module_sm___Zk66I`,
	"m": `Checkbox-module_m___lPYgo`,
	"l": `Checkbox-module_l___Lgpyj`
};
export default ___CSS_LOADER_EXPORT___;
