import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface Venue {
	Venue_ID: number;
	Venue_Name: string;
}

interface ListVenuesState {
	venues: Array<Venue>;
	loading: boolean;
	error: string | null;
	init: boolean;
}

const initialState: ListVenuesState = {
	venues: [],
	loading: false,
	error: null,
	init: false,
};

export const getListVenuesSales = createAsyncThunk<Array<Venue>, undefined, { rejectValue: string }>(
	"listVenues/getListVenues",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<Venue>>("/api/Sales/ListVenues");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listVenuesSalesSlice = createSlice({
	name: "listVenuesSales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListVenuesSales.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.init = true;
			})
			.addCase(getListVenuesSales.fulfilled, (state, action: PayloadAction<Array<Venue>>) => {
				state.loading = false;
				state.venues = action.payload;
			})
			.addCase(getListVenuesSales.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch venues";
			});
	},
});
