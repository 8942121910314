import React from "react";
import inventoryStyles from "../Inventory.module.scss";

import { formatCurrency } from "@src/components/utils/numberUtils";
import _ from "lodash";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";

interface IBottomContainerType {
	data: Array<EventInventoryRecord>;
}

export const BottomContainer: React.FC<IBottomContainerType> = ({ data }) => {
	const listings = data.length;
	const quantity = _.sumBy(data, (x) => x.Quantity);

	const cost = _.sumBy(data, (x) => x.Item_Cost);
	const price = _.sumBy(data, (x) => x.Item_Price);

	return (
		<div className={inventoryStyles.rightContent}>
			<div>Listings: {listings}</div>
			<div>Qty: {quantity}</div>
			<div>Cost: {formatCurrency(cost)}</div>
			<div>Price: {formatCurrency(price)}</div>
		</div>
	);
};
