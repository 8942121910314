import { pricingActions } from "@src/store";
import { useAppDispatch } from "@src/store/hooks";
import { IInitialState } from "@src/store/pricing/getSharedSettings";
import React, { CSSProperties } from "react";
import { Dropdown, IItems } from "../dropdown/Dropdown";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { clx } from "../utils/stringUtils";
import styles from "./Splits.module.scss";

interface ISplits {
	wrapperStyle?: CSSProperties;
	allowedSplits: IInitialState["allowedSplits"];
}

export const Splits: React.FC<ISplits> = ({ wrapperStyle, allowedSplits }) => {
	const dispatch = useAppDispatch();
	const changeSplits = (value: string | number | undefined) => {
		if (typeof value === "string" || typeof value === "undefined") return;
		dispatch(pricingActions.editSplits(value));
	};

	const items: Array<IItems> = [
		{ id: 1, label: "Default", value: -1, onClick: changeSplits },
		{ id: 2, label: "Pairs", value: 2, onClick: changeSplits },
		{ id: 3, label: "Any", value: 1, onClick: changeSplits },
		{ id: 4, label: "None", value: 0, onClick: changeSplits },
	];

	const buttonText = items.find((x) => x.value === allowedSplits)?.label ?? "Default";

	return (
		<div className={clx(styles.main)} style={wrapperStyle}>
			<div className={styles.headline}>Splits</div>
			<div className={styles.options}>
				<div className={styles.buttons} style={{ flex: 1 }}>
					<Dropdown
						onClick={() => mixpanelTrack(MixpanelCategory.SPLITS_INTERACTED)}
						isDisabled={false}
						items={items}
						buttonText={buttonText}
					/>
				</div>
			</div>
		</div>
	);
};
