import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface Category {
	Cat_ID: number;
	Cat_Desc: string;
	Cat_Type: string;
}

interface ListCategoriesState {
	categories: Array<Category>;
	loading: boolean;
	error: string | null;
	init: boolean;
}

const initialState: ListCategoriesState = {
	categories: [],
	loading: false,
	error: null,
	init: false,
};

export const getListCategoriesSales = createAsyncThunk<Array<Category>, undefined, { rejectValue: string }>(
	"listCategories/getListCategories",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<Category>>("/api/Sales/ListCategories");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listCategoriesSalesSlice = createSlice({
	name: "listCategoriesSales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListCategoriesSales.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.init = true;
			})
			.addCase(getListCategoriesSales.fulfilled, (state, action: PayloadAction<Array<Category>>) => {
				state.loading = false;
				state.categories = action.payload;
			})
			.addCase(getListCategoriesSales.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch categories";
			});
	},
});
