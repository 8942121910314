import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { EventRecord } from "@src/store/pricing/queryEventsSlice";
import { StylesConfig } from "react-select";

export interface OptionType<T = string | number> {
	value: T;
	label: string;
}

export const tagConfig: StylesConfig<OptionType> = {
	control: (provided, state) => ({
		...provided,
		minHeight: "20px",
		fontSize: "12px",
		borderColor: state.isFocused ? "none" : "lightgray",
		boxShadow: "none",
		"&:hover": {
			borderColor: "gray",
		},
	}),
	input: (provided) => ({
		...provided,
		padding: "0px",
		margin: "1px",
	}),
	container: (provided) => ({
		...provided,
		flexGrow: 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		padding: "0px 2px",
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: "0px 2px",
	}),
	menu: (provided) => ({
		...provided,
		background: "white",
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: "12px",
		backgroundColor: "white",
		color: state.isSelected ? "var(--legendBlue)" : "black",
		"&:hover": {
			backgroundColor: "#d1d1db",
		},
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: "12px",
	}),
	loadingMessage: (provided) => ({
		...provided,
		fontSize: "12px",
	}),
};

export const filtersConfig = (data: string, controlSize: "m" | "sm"): StylesConfig<OptionType> => ({
	control: (provided) => ({
		...provided,
		minHeight: "20px",
		height: controlSize == "sm" ? "25px" : undefined,
		fontSize: "12px",
		borderColor: "#d8d5d5",
		boxShadow: "none",
		"&:hover": {
			borderColor: "#d8d5d5",
		},
	}),
	input: (provided) => ({
		...provided,
		padding: "0px",
		margin: "1px",
	}),
	container: (provided) => ({
		...provided,
		flexGrow: 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		padding: "0px 2px",
		height: controlSize == "sm" ? "25px" : undefined,
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: "0px 2px",
	}),
	menu: (provided) => ({
		...provided,
		background: "white",
		border: "1px solid var(--itemBorder)",
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: "200px",
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: "12px",
		backgroundColor: state.isSelected ? "#EDEEF4" : "white",
		color: "black",
		"&:hover": {
			backgroundColor: "#EDEEF4",
		},
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: "12px",
	}),
	loadingMessage: (provided) => ({
		...provided,
		fontSize: "12px",
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: "4px",
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: "12px",
		color: "#afb2b8",
		whiteSpace: "nowrap",
	}),
});

export const editCustomComponents = {
	DropdownIndicator: null,
};

export const getEventOptions = (events: Array<EventRecord>, name: "Cat_Type" | "Event_Headliner" | "Venue_Name" | "Event_ID") => {
	if (events.length == 0) return [];
	return Array.from(new Set(events.map((x) => x[name]))).map((x) => ({
		label: String(x),
		value: String(x),
	}));
};

export const getInventoryOptions = (
	inventory: Array<EventInventoryRecord>,
	name: "Listing_ID" | "Row" | "Days_Since_Last_Price_Update" | "PO_ID",
) => {
	return Array.from(new Set(inventory.map((x) => x[name]))).map((x) => ({
		label: String(x),
		value: String(x),
	}));
};
