import { ArrowDownIcon, EyeHide, EyeShow, Trashcan } from "@src/assets/general-icons";
import { MagnifyingGlassIcon } from "@src/assets/general-icons/MagnifyingGlassIcon";
import helperStyles from "@src/components/helpers.module.scss";
import { pricingActions } from "@src/store";
import { useAppDispatch } from "@src/store/hooks";
import { ISavedRules } from "@src/store/pricing/savedRulesSlice";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { Button, ButtonVariant } from "../button/Button";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { transformMarkups } from "../utils/savedRulesUtils";
import styles from "./Dropdown.module.scss";
import ruleStyles from "./RulesDropdown.module.scss";

interface IRulesDropdown extends React.ComponentProps<"div"> {
	data: Array<ISavedRules>;
	error: string | null;
	onRulePick: (data: ISavedRules) => void;
}

export const RulesDropdown: React.FC<IRulesDropdown> = ({ data, error, onRulePick, ...rest }) => {
	const [open, setOpen] = useState(false);
	const [picked, setPicked] = useState<ISavedRules | null>(null);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [search, setSearch] = useState("");
	const dispatch = useAppDispatch();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

	const filteredRules = data.filter((i) => i.RuleName.toLowerCase().includes(search.toLowerCase()));
	const toggleOpen = () => {
		setOpen((prev) => !prev);
		mixpanelTrack(MixpanelCategory.RULE_DROPDOWN_TOGGLED, { data: !open });
	};

	const handleSplitName = (item: number) => {
		switch (item) {
			case -1:
				return "Default";
			case 0:
				return "None";
			case 1:
				return "Any";
			case 2:
				return "Pairs";
		}
	};

	const mappedItems = filteredRules?.map((item) => {
		const { RuleName, RuleId, AllowedSplits, Visibility, Markups } = item;
		const isPicked = picked?.RuleName === RuleName;
		const handleSetPick = () => {
			setPicked(item);
			toggleOpen();
			onRulePick(item);
		};

		const onRuleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			if (!RuleId) {
				toast.warning("Rule doesn't have an ID");
				return;
			}
			dispatch(pricingActions.deleteRule({ RuleId }));
		};

		const icons = transformMarkups(Markups.map((x) => x.Export_Destination_ID));
		const mappedIcons = icons.map((i) => (
			<div key={i.id} className={ruleStyles.smallIconContainer}>
				{i.icon}
			</div>
		));

		return (
			<div
				data-is-selected={isPicked}
				className={ruleStyles.singleItem}
				aria-hidden="true"
				onClick={handleSetPick}
				key={RuleId}>
				<div className={ruleStyles.name}>{RuleName}</div>
				<div className={ruleStyles.seats}>{Visibility === "HIDE" ? <EyeHide /> : <EyeShow />}</div>
				<div className={ruleStyles.splits}>{handleSplitName(AllowedSplits)}</div>
				<div className={ruleStyles.sharedTo}>{mappedIcons}</div>
				<div aria-hidden onClick={onRuleDelete} className={ruleStyles.trashCan}>
					<Trashcan />
				</div>
			</div>
		);
	});

	const content = mappedItems.length > 0 ? mappedItems : <div className={ruleStyles.item}>No options</div>;
	const dropdownLabel = error ?? picked?.RuleName ?? "Select Saved Rule";

	const handleOutsideClick = useCallback((event: MouseEvent) => {
		if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	}, []);

	useEffect(() => {
		if (open) {
			document.addEventListener("mousedown", handleOutsideClick);
		}
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [open, handleOutsideClick]);

	return (
		<div ref={buttonRef} {...rest} className={styles.dropdownContainer}>
			<Button style={{ width: "100%" }} onClick={toggleOpen} variant={ButtonVariant.Tertiary}>
				<div className={`${styles.dropdownButton} ${helperStyles.center}`}>
					<div>{dropdownLabel}</div>
					<div>
						<ArrowDownIcon />
					</div>
				</div>
			</Button>
			{open && (
				<div className={ruleStyles.rulesItemsContainer}>
					<div className={ruleStyles.inputContainer}>
						<input placeholder="Search Rule" onChange={handleSearch} />
						<MagnifyingGlassIcon />
					</div>
					<div className={ruleStyles.headerLabels}>
						<div className={ruleStyles.name}>Rule Name</div>
						<div className={ruleStyles.seats}>Seats</div>
						<div className={ruleStyles.splits}>Splits</div>
						<div className={ruleStyles.sharedTo}>Shared to</div>
					</div>
					<div className={ruleStyles.itemsContainer}>{content}</div>
				</div>
			)}
		</div>
	);
};
