// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InventoryHeadline-module_headlineContainer___XYm59 {
  display: flex;
  gap: 1.6rem;
}

.InventoryHeadline-module_headline___QSpBN {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/children/pricing/components/inventory/components/InventoryHeadline.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,WAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;AACD","sourcesContent":[".headlineContainer {\r\n\tdisplay: flex;\r\n\tgap: 1.6rem;\r\n}\r\n\r\n.headline {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headlineContainer": `InventoryHeadline-module_headlineContainer___XYm59`,
	"headline": `InventoryHeadline-module_headline___QSpBN`
};
export default ___CSS_LOADER_EXPORT___;
