import { ResetIcon } from "@src/assets/general-icons";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { IInputRef, Input } from "../input/Input";
import { MyTooltip } from "../tooltip/Tooltip";
import { clx } from "../utils/stringUtils";
import styles from "./CellRenderer.module.scss";
import { IColumn, IRowDataValueChangeProps, TExtend } from "./VGrid";

export interface ICellRendererProps<T extends TExtend> {
	column: IColumn<T>;
	rowData: T;
	theme: "primary" | "secondary";
	onRowDataValueChange?: (params: IRowDataValueChangeProps<T>) => void;
	rowId: string | number;
	changesMap: Map<string, IRowDataValueChangeProps<T>>;
	isSaving?: boolean;
	disableBorder?: boolean;
}

export const CellRenderer = <T extends TExtend>({
	column,
	theme = "primary",
	rowData,
	onRowDataValueChange,
	rowId,
	changesMap,
	disableBorder,
	isSaving = false,
}: ICellRendererProps<T>) => {
	const inputRef = useRef<IInputRef>(null);

	const randomId = btoa(String(Math.random())).slice(0, -2);
	const hasTooltip = column.hasTooltip;
	const changeMapId = `row:${rowId}-col:${column.id}`;
	const value = column.valueGetter?.({ rowData, column }) || (column.accessor ? rowData[column.accessor] : "");

	const [state, setState] = useState<{
		isEdit: boolean;
		originalValue: typeof value;
		value: typeof value;
	}>({
		isEdit: false,
		originalValue: value,
		value: changesMap.has(changeMapId) ? changesMap.get(changeMapId)?.value : value,
	});

	const isModified = state.originalValue != state.value;

	const params = { rowData, value, column };
	const displayValue = column.formatValue?.(state.value, params) ?? state.value;
	const title = column.disableTitle ? undefined : (column.formatTooltip?.(state.value, params) ?? displayValue);
	let content = column.cellRenderer?.(params) ?? (
		<div
			title={typeof title == "object" ? "" : title}
			className={clx({
				[styles.content]: true,
				[styles.dirty]: isModified,
				[column.getClassName?.(params) ?? ""]: true,
			})}
			style={{
				width: column.width,
				...(column.fullWidth ? { width: "100%" } : {}),
				...(column.getStyle?.({ column, rowData, value }) || {}),
			}}>
			<div className={styles.value}>{displayValue}</div>
			{isModified && (
				<button
					className={clx({ [styles.resetIcon]: true })}
					onClick={() => {
						if (!column.accessor) return;
						onRowDataValueChange?.({
							accesor: column.accessor,
							value: state.originalValue,
							originalValue: state.originalValue,
							columnId: column.id,
							rowId: rowId,
							rowData: rowData,
						});
						setState((x) => ({ ...x, value: x.originalValue }));
					}}>
					<ResetIcon />
				</button>
			)}
		</div>
	);
	const isEditable = typeof column.isEditable == "function" ? column.isEditable(params) : !!column.isEditable;

	if (hasTooltip) {
		content = <MyTooltip place="top-start" id={randomId} element={<>{content}</>} />;
	}

	useEffect(() => {
		if (!state.isEdit || !inputRef.current) return;
		inputRef.current.focus();
		inputRef.current.select();
	}, [state.isEdit]);

	const handleSave = () => {
		if (!state.isEdit || !column.accessor) return;
		onRowDataValueChange?.({
			value: state.value,
			originalValue: state.originalValue,
			columnId: column.id,
			accesor: column.accessor,
			rowId: rowId,
			rowData: rowData,
		});
		state.isEdit = false; // this works as a useRef or static state to prevent reSave
		setState((x) => ({ ...x, isEdit: false }));
	};
	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSave();
		} else if (e.key === "Escape") {
			setState((x) => ({ ...x, isEdit: false, value: x.originalValue }));
		}
	};

	return (
		<td
			className={clx({
				[styles.cell]: true,
				[styles[`${theme}Theme`]]: true,
				[styles.editing]: state.isEdit,
				[styles.modified]: isModified,
				[styles.isEditable]: isEditable,
				[styles.isSticky]: !!column.isSticky,
				isSticky: true,
			})}
			style={{
				width: column.width,
				minWidth: column?.minWidth,
				...(column.fullWidth ? { width: "100%" } : {}),
				...(column.getCellStyle?.({ column, rowData, value }) || {}),
				border: disableBorder ? "none" : "auto",
			}}>
			{!isSaving && isEditable && !isModified && !state.isEdit && (
				<button
					className={clx({ [styles.editBtn]: true, [column.editBtnClassName ?? ""]: !!column.editBtnClassName })}
					onClick={() => {
						if (column.onEditClick) {
							column.onEditClick({
								rowData,
								value: state.value,
								column: column,
							});
							return;
						}
						if (!isEditable) return;
						setState((x) => ({ ...x, isEdit: !state.isEdit }));
					}}>
					{column.editableLabel ?? "Edit"}
				</button>
			)}
			{state.isEdit ? (
				<Input
					disableXbutton
					ref={inputRef}
					type="number"
					inputPrefix="$"
					className={styles.input}
					wrapperClassName={styles.inputWrapper}
					inputContainerClassName={styles.inputContainer}
					value={state.value}
					controlSize={column.editInputSize ?? "sm"}
					onChange={(e) => {
						setState((x) => ({ ...x, value: e.target.value }));
					}}
					onBlur={() => {
						handleSave();
					}}
					onKeyDown={handleKeyDown}
				/>
			) : (
				content
			)}
		</td>
	);
};
