import { Dropdown } from "@src/components/dropdown/Dropdown";
import { IModalHandle, Modal } from "@src/components/modal/Modal";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { eventActions } from "@src/store";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";

import { ArrowIcon, ResetIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { IRowDataValueChangeProps } from "@src/components/vGrid/VGrid";
import React, { useRef, useState } from "react";
import styles from "./InventoryHeadline.module.scss";
import { SharingSettings } from "./SharingSettings";
import { useAppDispatch } from "@src/store/hooks";

interface IInventoryHeadline {
	data: Array<EventInventoryRecord>;
	changesMap: Map<string, IRowDataValueChangeProps<EventInventoryRecord>>;
	onDiscard?: () => Promise<void>;
	onApply?: () => Promise<void>;
}

export const InventoryHeadline: React.FC<IInventoryHeadline> = ({ data, changesMap, onDiscard, onApply }) => {
	const sharingRef = useRef<IModalHandle>(null);
	const [state, setState] = useState({
		isSaving: false,
	});

	const dispatch = useAppDispatch();

	const openAutopricerModal = () => {
		dispatch(eventActions.showComingSoon({ showComingSoon: true }));
		// dispatch(eventActions.setInventory({ inventoryAction: InventoryActionEnum.Edit }));
		// dispatch(eventActions.setManageInventoryTab(ManageInventoryTabEnum.Autopricer));
	};

	const openSharingModal = () => sharingRef.current?.openModal();
	const closeSharingModal = () => sharingRef.current?.closeModal();

	const onAutoPricerClick = () => {
		openAutopricerModal();
		mixpanelTrack(MixpanelCategory.AUTO_PRICER_CLICKED, {
			data,
		});
	};

	const onSharingOptionsClick = () => {
		openSharingModal();
		mixpanelTrack(MixpanelCategory.SHARKING_OPTIONS_CLICKED, {
			data,
		});
	};

	const items = [
		{
			id: 1,
			label: "Auto Pricer",
			onClick: onAutoPricerClick,
		},
		{ id: 2, label: "Sharing Options", onClick: onSharingOptionsClick },
	];

	return (
		<div className={styles.headlineContainer}>
			<div className={styles.headline}>Inventory</div>
			<div style={{ display: "flex", gap: "5px" }}>
				<Dropdown minHolderWidth={200} items={items} isDisabled={data.length < 2} buttonText="Bulk Actions" />
				{changesMap.size > 0 && (
					<>
						<Button
							onClick={onDiscard}
							disabled={state.isSaving}
							style={{ padding: "0px 9px", fontSize: "12px", display: "flex", alignItems: "center", gap: "5px" }}
							controlSize="sm"
							variant={ButtonVariant.Tertiary}>
							<ResetIcon /> Discard
						</Button>
						<Button
							isLoading={state.isSaving}
							onClick={async () => {
								setState({ isSaving: true });
								await onApply?.();
								setState({ isSaving: false });
							}}
							style={{ padding: "0px 9px", fontSize: "12px", display: "flex", alignItems: "center", gap: "5px" }}
							controlSize="sm"
							variant={ButtonVariant.Secondary}>
							<ArrowIcon /> Apply
						</Button>
					</>
				)}
			</div>
			<Modal ref={sharingRef}>
				<SharingSettings rowsData={data} handleClose={closeSharingModal} />
			</Modal>
		</div>
	);
};
