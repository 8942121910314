import { pricingActions } from "@src/store";
import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { Button, ButtonVariant } from "../button/Button";
import { allMarketplaces } from "../utils/marketplaceUtils";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { clx } from "../utils/stringUtils";
import styles from "./MarketPlaces.module.scss";

export const MarketPlaces: React.FC<{
	items: Array<number>;
	wrapperStyle?: CSSProperties;
}> = ({ wrapperStyle, items }) => {
	const dispatch = useDispatch();

	const toggleAll = () => {
		const allIDs = allMarketplaces.map((x) => x.id);
		dispatch(pricingActions.setSavedRuleIds(allIDs));
	};

	return (
		<div className={styles.main} style={wrapperStyle}>
			<div className={styles.headline}>Marketplaces</div>
			<div className={styles.content}>
				<div className={styles.allButton}>
					<Button onClick={toggleAll} variant={ButtonVariant.Tertiary} className={styles.item}>
						All
					</Button>
				</div>
				<div className={styles.icons}>
					{allMarketplaces.map((item) => {
						const isSelected = items.includes(item.id);
						const handleClick = () => {
							dispatch(pricingActions.toggleRule(item.id));
							mixpanelTrack(MixpanelCategory.MARKETPLACE_TOGGLED, {
								data: { marketplace: item.name, state: !isSelected ? "Selected" : "Unselected" },
							});
						};

						return (
							<Button
								key={item.id}
								onClick={handleClick}
								className={clx({ [styles.selected]: isSelected, [styles.item]: true })}>
								{item.icon}
							</Button>
						);
					})}
				</div>
			</div>
		</div>
	);
};
