import { DotsIcon } from "@src/assets/general-icons";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { TableExpandButton } from "@src/components/button/TableExpandButton";
import helperStyles from "@src/components/helpers.module.scss";
import { Loader } from "@src/components/loader/Loader";
import { SectionContainer } from "@src/components/sectionContainer/SectionContainer";
import { Switch } from "@src/components/switch/Switch";
import { convertSeatNumbersToSeats } from "@src/components/utils/columnUtils";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { IRowDataValueChangeProps, VGrid, VGridRef } from "@src/components/vGrid/VGrid";
import { eventActions, pricingActions, settingsActions, systemActions } from "@src/store";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { InventoryActionEnum } from "@src/store/pricing/selectionSlice";
import { IListingToUpdate } from "@src/store/pricing/updateListingPrices";
import { IInvoiceWithCost } from "@src/store/system/updateCostPerTicket";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BottomContainer } from "./components/BottomContainer";
import { InventoryHeadline } from "./components/InventoryHeadline";
import styles from "./Inventory.module.scss";

// ⚠️ Experimental: External function to programmatically select a specific row in the VGrid
// Prevents infinite render loop and is used in ApStatus.tsx file4
export let externalAutoPricerRowClick: (row?: EventInventoryRecord) => void = () => void 0;

export const Inventory: React.FC = () => {
	const dispatch = useAppDispatch();
	const vGridRef = useRef<VGridRef>(null);

	const [searchParams] = useSearchParams();
	const [pageState, setPageState] = useState({
		changesMap: new Map<string, IRowDataValueChangeProps<EventInventoryRecord>>(),
		changesMapVersion: 0,
	});

	const showSoldEvents = searchParams.get("showSold") === "true";
	const [showSold, setShowSold] = useState(showSoldEvents);

	const handleChecked = () => setShowSold((prev) => !prev);

	useEffect(() => {
		setShowSold(showSoldEvents);
	}, [showSoldEvents]);

	const eventInventoryAll = useAppSelector((state) => state.pricing.eventInventoryAll);
	const eventItem = useAppSelector((state) => state.pricing.selection.event);
	const inventoryItemIds = useAppSelector((state) => state.pricing.selection.inventoryItemIds);
	const inventoryItems = _.filter(eventInventoryAll.data, (x) => inventoryItemIds.includes(x.Item_ID));
	const companyName = useAppSelector((state) => state.settings.companyName);
	const columnsInventory = useAppSelector((state) => state.pricing.settings.columns.Inventory);
	const isSaving = useAppSelector((state) => state.pricing.updateListingPrices.loading);
	const listingId = searchParams.get("listingId")?.toLocaleLowerCase() ?? "";
	const poId = searchParams.get("poId")?.toLocaleLowerCase() ?? "";

	const handleSelectable = useCallback(
		(rowsData: Array<EventInventoryRecord>) => {
			dispatch(
				eventActions.setInventory({
					inventoryItemIds: rowsData.map((x) => x.Item_ID),
					inventoryAction: InventoryActionEnum.Select,
				}),
			);
		},
		[dispatch],
	);

	externalAutoPricerRowClick = useCallback((row?: EventInventoryRecord) => {
		if (!row) return;
		const idPrefix = row?.Invoice_ID ? "Sold" : "NotSold";
		vGridRef.current?.strictRowSet([{ id: `${idPrefix}-${row?.Listing_ID}`, isChecked: true }]);
	}, []);

	const openAutopricerModal = () => {
		if (!inventoryItemIds.length) return;

		if (inventoryItemIds.length > 1) {
			dispatch(eventActions.showComingSoon({ showComingSoon: true }));
			return;
		}
		dispatch(settingsActions.setAutoPricerPopup(true));
	};

	const tableContent = useMemo(() => {
		if (eventInventoryAll.loading) return <Loader />;
		const eventInventory = eventInventoryAll.data;
		if (!eventInventory.length) return <div className={helperStyles.fullWidthHeightCenter}>No data</div>;

		let rowsData = _.map(
			_.groupBy(
				_.sortBy(eventInventory, "Listing_ID"),
				(x) => (x.Invoice_ID ? x.Invoice_ID : x.Listing_ID) + (x.Invoice_ID ? "Sold" : "NotSold") + x.PO_ID,
			),
			(value) => {
				const seatNumbers = value.map((x) => x.Seat_Number);
				return {
					...value[0],
					Seats: convertSeatNumbersToSeats(seatNumbers),
					Quantity: value.length,
					Item_Cost: value.reduce((acc, curr) => acc + curr.Item_Cost, 0),
				};
			},
		).filter((x) => {
			return showSold || !x.Invoice_ID;
		});

		if (listingId) {
			rowsData = rowsData.filter((x) => x.Listing_ID == +listingId);
		}

		if (poId) {
			rowsData = rowsData.filter((x) => x.PO_ID == +poId);
		}

		return rowsData.length ? (
			<VGrid<EventInventoryRecord>
				ref={vGridRef}
				rowsData={rowsData}
				columns={columnsInventory}
				wrapperStyle={{ padding: "0px 8px" }}
				rowSelection="multiple"
				onSelectionChange={handleSelectable}
				getCheckboxInputStyle={(rowData) => {
					return rowData.Invoice_ID ? { display: "none" } : {};
				}}
				getCheckboxIsDisabled={(rowData) => {
					return !!rowData.Invoice_ID;
				}}
				getRowNodeId={(data) => {
					return `${data.Invoice_ID ? "Sold" : "NotSold"}-${data.Listing_ID}`;
				}}
				getRowClassName={({ rowData }) => (rowData.Invoice_ID ? helperStyles.rowWithSold : "")}
				changesMapVersion={pageState.changesMapVersion}
				onChangeMapChange={(changesMap) => {
					dispatch(eventActions.setInventoryHasChanges({ inventoryHasChanges: changesMap.size > 0 }));
					setPageState((x) => ({ ...x, changesMap }));
				}}
				isSaving={isSaving}
				bottomContainer={<BottomContainer data={rowsData} />}
			/>
		) : (
			<div className={helperStyles.fullWidthHeightCenter}>{eventInventoryAll.error ?? "No data"}</div>
		);
	}, [
		showSold,
		eventInventoryAll.loading,
		handleSelectable,
		columnsInventory,
		pageState.changesMapVersion,
		isSaving,
		dispatch,
		eventInventoryAll.data,
		eventInventoryAll.error,
		listingId,
		poId,
	]);

	const autoPricerButtonText = inventoryItemIds.length > 1 ? "Grouped Auto Pricer" : "Auto Pricer";

	const fetchInventory = useCallback(
		(skipCache = false) => {
			if (!eventItem?.Event_ID) {
				dispatch(
					pricingActions.updateInventoryAll({
						eventId: 0,
						data: [],
					}),
				);
				dispatch(
					eventActions.setInventory({
						inventoryItemIds: [],
						inventoryAction: InventoryActionEnum.Select,
					}),
				);
				return;
			}
			dispatch(
				pricingActions.queryEventInventoryAll({
					EventId: eventItem.Event_ID,
					CompanyName: companyName,
					skipCache,
				}),
			);
		},
		[companyName, dispatch, eventItem?.Event_ID],
	);

	const handleCustomize = () => {
		dispatch(settingsActions.setCustomizeTableTab("Inventory"));

		mixpanelTrack(MixpanelCategory.CUSTOMIZE_CLICKED, {
			data: "Inventory customize button clicked",
		});
	};

	useEffect(() => {
		fetchInventory();
	}, [eventItem?.Event_ID, fetchInventory]);

	//COMMENT
	// useEffect(() => {
	// 	if (!eventInventoryAll.init) return;
	// 	const eventInventory = eventInventoryAll.data;
	// 	const rowsData = _.map(
	// 		_.groupBy(_.sortBy(eventInventory, "Listing_ID"), (x) => x.Listing_ID + (x.Invoice_ID ? "Sold" : "NotSold")),
	// 		(value) => {
	// 			const seatNumbers = value.map((x) => x.Seat_Number);
	// 			return {
	// 				...value[0],
	// 				Seats: convertSeatNumbersToSeats(seatNumbers),
	// 				Quantity: value.length,
	// 				Item_Cost: value.reduce((acc, curr) => acc + curr.Item_Cost, 0),
	// 			};
	// 		},
	// 	).filter((x) => {
	// 		return showSold || !x.Invoice_ID;
	// 	});
	// 	if (!rowsData.length) return;

	// 	dispatch(eventActions.setInventory({ inventoryItemIds: [rowsData[0].Item_ID], inventoryAction: InventoryActionEnum.Edit }));
	// }, [eventInventoryAll.version]);

	const expandInventory = useAppSelector((state) => state.settings.expandInventory);
	const handleInventoryExpansion = () => {
		dispatch(settingsActions.setExpandInventory(!expandInventory));
		dispatch(eventActions.setInventory({ inventoryAction: InventoryActionEnum.Select }));
	};
	const resetChangeMap = () => {
		dispatch(eventActions.setInventoryHasChanges({ inventoryHasChanges: false }));
		setPageState((x) => ({ ...x, changesMap: new Map(), changesMapVersion: x.changesMapVersion + 1 }));
	};

	return (
		<SectionContainer
			bottomButton={<TableExpandButton isExpanded={expandInventory} onClick={handleInventoryExpansion} />}
			headline={
				<InventoryHeadline
					data={inventoryItems}
					changesMap={pageState.changesMap}
					onApply={async () => {
						const listingsToUpdate = new Array<IListingToUpdate>();
						const costToUpdate = new Array<IInvoiceWithCost>();

						pageState.changesMap.forEach((value) => {
							if (value.columnId == "Cost") {
								const invoiceID = value.rowData.Invoice_ID;
								if (invoiceID)
									costToUpdate.push({
										Invoice_ID: invoiceID,
										Item_Cost: Number(value.value),
									});
							} else {
								const listingId = value.rowData.Listing_ID;

								listingsToUpdate.push({
									listing_id: listingId,
									price: Number(value.value),
									face: null,
								});
							}
						});

						if (listingsToUpdate.length) await dispatch(pricingActions.updateListingPrices(listingsToUpdate));

						if (costToUpdate.length)
							await dispatch(
								systemActions.updateCostPerTicket({
									UpdatedCosts: costToUpdate,
								}),
							);

						resetChangeMap();
					}}
					onDiscard={async () => {
						resetChangeMap();
					}}
				/>
			}
			rightContent={
				<div className={styles.rightContent}>
					<Button
						onClick={() => fetchInventory(true)}
						style={{ minWidth: "64px", padding: "0px 9px", fontSize: "11px" }}
						controlSize="sm"
						variant={ButtonVariant.Tertiary}>
						Fetch
					</Button>
					<Button style={{ padding: "0px 9px", fontSize: "11px" }} controlSize="sm" variant={ButtonVariant.Tertiary}>
						<Switch checked={showSold} onChange={handleChecked} label="Show Sold" />
					</Button>
					<Button
						disabled={!inventoryItems.length}
						onClick={openAutopricerModal}
						style={{ minWidth: "94px", padding: "0px 9px", fontSize: "11px" }}
						controlSize="sm"
						variant={ButtonVariant.Tertiary}>
						{autoPricerButtonText}
					</Button>
					<Button
						onClick={handleCustomize}
						style={{ minWidth: "94px", padding: "0px 9px", fontSize: "11px" }}
						controlSize="sm"
						variant={ButtonVariant.Tertiary}>
						Customize
					</Button>
					<Button
						onClick={() => {
							dispatch(eventActions.showComingSoon({ showComingSoon: true }));
						}}
						style={{ minWidth: "32px" }}
						controlSize="sm"
						variant={ButtonVariant.Tertiary}>
						<DotsIcon />
					</Button>
				</div>
			}>
			{tableContent}
		</SectionContainer>
	);
};
