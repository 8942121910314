import React, { useRef, useEffect, CSSProperties } from "react";
import styles from "./Checkbox.module.scss";
import { clx } from "../utils/stringUtils";

interface CheckboxProps {
	indeterminate?: boolean;
	isChecked?: boolean;
	isDisabled?: boolean;
	controlSize?: "sm" | "m" | "l";
	onChange?: (checked: boolean) => void;
	inputStyle?: CSSProperties;
}

const Checkbox: React.FC<CheckboxProps> = ({
	indeterminate = false,
	isChecked = false,
	controlSize = "m",
	onChange,
	isDisabled = false,
	inputStyle = {},
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.indeterminate = indeterminate;
		}
	}, [indeterminate]);

	return (
		<input
			disabled={isDisabled}
			className={clx({ [styles.main]: true, [styles[controlSize]]: true })}
			style={inputStyle}
			type="checkbox"
			checked={isChecked}
			ref={inputRef}
			onClick={(e) => {
				e.stopPropagation();
			}}
			onChange={(e) => {
				onChange?.(e.target.checked);
			}}
		/>
	);
};

export default Checkbox;
