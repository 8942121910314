export type TNameValues = "category" | "saleFromDate" | "saleToDate" | "headliner" | "venue" | "eventFromDate" | "eventToDate";

type TSalesInputItem = {
	id: number;
	name: TNameValues;
	type: "text" | "date";
	placeholder: string;
	showType: "input" | "select" | "date";
};

export const salesInputItems: Array<TSalesInputItem> = [
	{
		id: 1,
		name: "category",
		type: "text",
		placeholder: "Category",
		showType: "select",
	},
	{
		id: 2,
		name: "saleFromDate",
		type: "date",
		placeholder: "Sales Start",
		showType: "date",
	},
	{
		id: 3,
		name: "saleToDate",
		type: "date",
		placeholder: "Sales End",
		showType: "date",
	},
	{
		id: 4,
		name: "headliner",
		type: "text",
		placeholder: "Headliner",
		showType: "select",
	},
	{
		id: 5,
		name: "venue",
		type: "text",
		placeholder: "Venue",
		showType: "select",
	},
	{
		id: 6,
		name: "eventFromDate",
		type: "date",
		placeholder: "Events End",
		showType: "date",
	},
	{
		id: 7,
		name: "eventToDate",
		type: "date",
		placeholder: "Events End",
		showType: "date",
	},
];
