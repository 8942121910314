import { DuplicateIcon, FilterIcon, XIcon } from "@src/assets/general-icons";
import React, { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { Button, ButtonVariant } from "../button/Button";
import { IModalHandle, Modal } from "../modal/Modal";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import styles from "./FiltersModal.module.scss";

interface IResetAll {
	queriesToReset?: Array<string>;
	resetFunction?: () => void;
}

interface IFiltersModal {
	customButton?: JSX.Element;
	buttonRightContent?: JSX.Element;
	children: JSX.Element;
	title?: string;
	onReset?: IResetAll;
	onOpenButtonClick?: () => void;
}

export const FiltersModal: React.FC<IFiltersModal> = (props) => {
	const { customButton, children, title, buttonRightContent, onReset, onOpenButtonClick } = props;
	const [params, setQueryParams] = useSearchParams();

	const filterModalRef = useRef<IModalHandle>(null);
	const openModal = () => filterModalRef.current?.openModal();
	const closeModal = () => filterModalRef.current?.closeModal();

	const openModalExtended = () => {
		openModal();
		onOpenButtonClick?.();
	};

	const handleSubmit = () => {
		// We don't need onSubmit here since the <Button type="submit"/> will bubble up
		// To the parent form inside the Header.tsx component, therefore submitting it.
		closeModal();
	};

	const handleReset = () => {
		if (!onReset) {
			console.log("Nothing to reset");
			return;
		}

		if (onReset.queriesToReset) {
			onReset.queriesToReset.forEach((x) => {
				params.delete(x);
				setQueryParams(params);
			});
		}

		onReset.resetFunction?.();
	};

	const copyUrlToClipboard = () => {
		if (!navigator.clipboard) {
			toast.warning("Feature not available on this wrowser");
			return;
		}
		navigator.clipboard.writeText(window.location.href);
		toast.success("Successfully copied URL to clipboard");

		mixpanelTrack(MixpanelCategory.COPY_URL_CLICKED, {
			data: window.location.href,
		});
	};

	const defaultOpenButton = (
		<Button style={{ width: "100px" }} type="button" onClick={openModalExtended} variant={ButtonVariant.Quaternary}>
			<div className={styles.filterButton}>
				<FilterIcon /> Filters {!params.size || <span>({params.size})</span>}
			</div>
		</Button>
	);

	return (
		<Modal ref={filterModalRef} customButton={customButton ?? defaultOpenButton}>
			<form onSubmit={handleSubmit} className={styles.root}>
				<div className={styles.header}>
					<div></div>
					<div>{title ?? "Filters"}</div>
					<div aria-hidden="true" onClick={closeModal}>
						<XIcon color="white" />
					</div>
				</div>
				<div className={styles.content}>
					<div className={styles.inner}>
						{children}
						<div className={styles.bottomButtonsContainer}>
							<Button onClick={handleReset} variant={ButtonVariant.Quinary}>
								Clear All
							</Button>
							<div className={styles.rightSideButtons}>
								{buttonRightContent}
								<Button onClick={copyUrlToClipboard} variant={ButtonVariant.Tertiary} disabled={!params.size}>
									<DuplicateIcon />
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.buttonContainer}>
					<Button
						style={{
							minWidth: "140px",
						}}
						type="submit">
						Apply Filters
					</Button>
				</div>
			</form>
		</Modal>
	);
};
