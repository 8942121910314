export const formatSeats = (seats: Array<number>) => {
	if (!seats.length) {
		return "/";
	} else if (seats.length === 1) {
		return seats[0];
	}

	return `${seats[0]} - ${seats[seats.length - 1]}`;
};

export const convertSeatNumbersToSeats = (numbers: Array<number>): string => {
	const sortedNumbers = [...numbers].sort((a, b) => a - b);

	let rangeStart = sortedNumbers[0];
	let rangeEnd = sortedNumbers[0];
	const ranges = new Array<string>();

	for (let i = 1; i < sortedNumbers.length; i++) {
		if (sortedNumbers[i] === rangeEnd + 1) {
			rangeEnd = sortedNumbers[i];
		} else {
			ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`);
			rangeStart = rangeEnd = sortedNumbers[i];
		}
	}
	ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`);

	return ranges.join(", ");
};
