import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";

interface ListSaleStatusesState {
	saleStatuses: Array<string>;
	loading: boolean;
	error: string | null;
	init: boolean;
}

const initialState: ListSaleStatusesState = {
	saleStatuses: [],
	loading: false,
	error: null,
	init: false,
};

export const getListSaleStatuses = createAsyncThunk<Array<string>, undefined, { rejectValue: string }>(
	"listSaleStatuses/getListSaleStatuses",
	async (_, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<string>>("/api/Sales/ListSaleStatuses");
			return response;
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const listSaleStatusesSlice = createSlice({
	name: "listSaleStatuses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getListSaleStatuses.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.init = true;
			})
			.addCase(getListSaleStatuses.fulfilled, (state, action: PayloadAction<Array<string>>) => {
				state.loading = false;
				state.saleStatuses = action.payload;
			})
			.addCase(getListSaleStatuses.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch sales statuses";
			});
	},
});
