import { useCallback, useState } from "react";

export const useForceUpdate = () => {
	const [_tick, setTick] = useState(0);
	const update = useCallback(() => {
		// console.info("tick", tick);
		setTick((x) => x + 1);
	}, []);
	return update;
};
