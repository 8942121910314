import { HeaderFiltersProps } from "@src/validation/HeaderFiltersValidation";
import { SalesFiltersProps } from "@src/validation/SalesHeaderFiltersValidation";

export const filtersEmptyValues: HeaderFiltersProps = {
	eventName: "",
	eventHeadliner: "",
	venue: "",
	startDate: "",
	endDate: "",
	smartSearch: "",
	tags: "",
	category: "",
	section: "",
	row: "",
	minQuantity: "",
	daysSinceLastUpdate: "",
	listingId: "",
	eventId: "",
	poId: "",
	poStartDate: "",
	poEndDate: "",
};

export const salesFiltersEmptyValues: SalesFiltersProps = {
	smartSearch: "",
	tags: "",
	category: "",
	headliner: "",
	venue: "",
	eventFromDate: "",
	eventToDate: "",
	exchangeName: "",
	paymentFromDate: "",
	paymentToDate: "",
	saleStatus: "",
	saleFromDate: "",
	saleToDate: "",
	sections: "",
	rows: "",
	minQuantity: "",
	invoiceId: "",
	eventId: "",
	buyerPOId: "",
};
