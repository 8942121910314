import * as yup from "yup";

export type SalesFiltersProps = yup.InferType<typeof SalesHeaderFiltersValidation>;

export const SalesHeaderFiltersValidation = yup.object().shape({
	companyName: yup.string().optional(),
	smartSearch: yup.string(),
	tags: yup.string(),
	category: yup.string(),
	headliner: yup.string(),
	venue: yup.string(),
	eventFromDate: yup.string(),
	eventToDate: yup.string(),
	exchangeName: yup.string(),
	paymentFromDate: yup.string(),
	paymentToDate: yup.string(),
	saleStatus: yup.string(),
	saleFromDate: yup.string(),
	saleToDate: yup.string(),
	sections: yup.string(),
	rows: yup.string(),
	minQuantity: yup.string(),
	invoiceId: yup.string(),
	eventId: yup.string(),
	buyerPOId: yup.string(),
});
