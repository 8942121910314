import { SortAscIcon } from "@src/assets/general-icons";
import { useForceUpdate } from "@src/hooks/useForceUpdate";
import _ from "lodash";
import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ResizableBox } from "react-resizable";
import { TableVirtuoso } from "react-virtuoso";
import { clx } from "../utils/stringUtils";
import { CellRenderer } from "./CellRenderer";
import Checkbox from "./Checkbox";
import styles from "./VGrid.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UNKNOWN = any;
export type TExtend = Record<string, UNKNOWN>;
export interface IValueGetterParams<T extends TExtend> {
	rowData: T;
	column: IColumn<T>;
}
export interface IHeaderRendererParams<T extends TExtend> {
	column: IColumn<T>;
}
export interface IParams<T extends TExtend, K> {
	rowData: T;
	value: K;
	column: IColumn<T>;
}

export type IColumn<T extends TExtend> = {
	[K in keyof T]: {
		id: string;
		accessor?: K;
		header?: string;
		width: number;
		valueGetter?: (params: IValueGetterParams<T>) => UNKNOWN;
		formatValue?: (value: T[K], params: IParams<T, T[K]>) => JSX.Element | string | number;
		formatTooltip?: (value: T[K], params: IParams<T, T[K]>) => JSX.Element | string | number;
		headerRenderer?: (params: IHeaderRendererParams<T>) => JSX.Element | string | number;
		cellRenderer?: (params: IParams<T, T[K]>) => JSX.Element | string | number;
		getClassName?: (params: IParams<T, T[K]>) => string;
		getStyle?: (params: IParams<T, T[K]>) => CSSProperties;
		getHeaderStyle?: (params: IHeaderRendererParams<T>) => CSSProperties;
		getCellStyle?: (params: IParams<T, T[K]>) => CSSProperties;
		isSticky?: boolean;
		isEditable?: boolean | ((params: IParams<T, T[K]>) => boolean);
		editableLabel?: string;
		onEditClick?: (params: IParams<T, T[K]>) => void;
		isDisabled?: (rowData: T) => boolean;
		checkboxInputStyle?: (rowData: T) => CSSProperties;
		minWidth?: number;
		maxWidth?: number;
		fullWidth?: boolean;
		hasTooltip?: boolean;
		disableTitle?: boolean;
		sortable?: boolean;
		isCurrency?: boolean;
		editInputSize?: "n" | "sm" | "xsm";
		editBtnClassName?: string;
	};
}[keyof T];

export interface IRowDataValueChangeProps<T extends TExtend> {
	accesor: keyof T;
	originalValue: UNKNOWN;
	value: UNKNOWN;
	columnId: string;
	rowId: string | number;
	rowData: T;
}
type IVGridProps<T extends TExtend> = {
	[K in keyof T]: {
		columns: Array<IColumn<T>>;
		rowsData: Array<T>;
		theme?: "primary" | "secondary";
		bottomContainer?: JSX.Element;
		rowSelection?: "none" | "single" | "multiple";
		wrapperStyle?: CSSProperties;
		selectedRowId?: string | number;
		initialPageIndex?: number;
		onSelectionChange?: (rows: Array<T>) => void;
		getRowClassName?: (params: { rowData: T }) => string;
		getTableHeaderClassName?: () => string;
		getRowStyle?: (params: IParams<T, T[K]>) => string;
		onPageIndexChange?: (pageIndex: number) => void;
		getRowNodeId: (row: T) => string | number;
		getCheckboxIsDisabled?: (rowData: T) => boolean;
		getCheckboxInputStyle?: (rowData: T) => CSSProperties;
		onRowDataValueChange?: (params: IRowDataValueChangeProps<T>) => void;
		onChangeMapChange?: (changesMap: Map<string, IRowDataValueChangeProps<T>>) => void;
		onRowSelection?: (data: T) => void;
		hasSelectedRowBorder?: boolean;
		changesMapVersion?: number;
		isSaving?: boolean;
		disableBorder?: boolean;
	};
}[keyof T];

interface ISort {
	columnId: string;
	direction: "asc" | "desc";
}
interface IState<T extends TExtend> {
	sort: ISort;
	selectedRowId?: string | number;
	columnSizes: Map<string, number>;
	changesMap: Map<string, IRowDataValueChangeProps<T>>;
}

export interface VGridRef {
	setCheckedRowIds: (rows: Array<{ id: string | number; isChecked: boolean }>) => void;
	strictRowSet: (rows: Array<{ id: string | number; isChecked: boolean }>) => void;
	clearSelection: () => void;
}

export const VGridInner = <T extends TExtend>(
	{
		columns,
		rowsData,
		theme = "primary",
		bottomContainer,
		rowSelection = "none",
		wrapperStyle,
		onSelectionChange,
		getRowClassName,
		getTableHeaderClassName,
		getRowNodeId,
		getCheckboxIsDisabled,
		getCheckboxInputStyle,
		onRowDataValueChange,
		onChangeMapChange,
		onRowSelection,
		selectedRowId,
		changesMapVersion = 0,
		isSaving = false,
		disableBorder = false,
		hasSelectedRowBorder = false,
	}: IVGridProps<T>,
	ref: React.Ref<VGridRef>,
) => {
	const [staticState] = useState({
		checkedRowIds: new Set<string | number>(),
	});

	const forceUpdate = useForceUpdate();

	const [state, setState] = useState<IState<T>>({
		sort: {
			columnId: "",
			direction: "asc",
		},
		selectedRowId: selectedRowId,
		columnSizes: new Map<string, number>(),
		changesMap: new Map<string, IRowDataValueChangeProps<T>>(),
	});

	const _onSelectionChange = () => {
		forceUpdate();
		onSelectionChange?.(rowsData.filter((x) => staticState.checkedRowIds.has(getRowNodeId(x))));
	};

	useImperativeHandle(ref, () => ({
		setCheckedRowIds: (rows) => {
			rows.forEach((row) => {
				if (row.isChecked) staticState.checkedRowIds.add(row.id);
				else staticState.checkedRowIds.delete(row.id);
			});
			_onSelectionChange();
		},
		strictRowSet: (rows) => {
			const row = rows[0];
			staticState.checkedRowIds = new Set();
			if (row.isChecked) staticState.checkedRowIds.add(row.id);
			_onSelectionChange();
		},
		clearSelection: () => {
			staticState.checkedRowIds.clear();
			_onSelectionChange();
		},
	}));

	useEffect(() => {
		if (!selectedRowId) return;
		setState((x) => ({ ...x, selectedRowId: selectedRowId }));
	}, [selectedRowId, state.selectedRowId]);

	useEffect(() => {
		if (!changesMapVersion) return;
		setState((x) => ({ ...x, changesMap: new Map() }));
	}, [changesMapVersion]);

	const handleParentCheckboxChange = (isChecked: boolean) => {
		if (isChecked) {
			staticState.checkedRowIds.clear();
			rowsData.forEach((x) => {
				const isDisabled = !!getCheckboxIsDisabled?.(x);
				if (isDisabled) return;
				staticState.checkedRowIds.add(getRowNodeId(x));
			});
		} else {
			staticState.checkedRowIds.clear();
		}
		_onSelectionChange();
	};

	const customColumns = (() => {
		let newColumns = columns.map<IColumn<T> & { width?: number }>((column) => {
			return {
				...column,
				width: state.columnSizes.get(column.id) ?? column.width ?? column.minWidth,
				minWidth: column?.minWidth,
			};
		});

		if (rowSelection == "multiple") {
			newColumns.unshift({
				id: "__checkbox_column",
				width: 30,
				headerRenderer: () => {
					const enabledRows = rowsData.filter((x) => !getCheckboxIsDisabled?.(x));
					const isChecked =
						enabledRows.length > 0 && enabledRows.every((x) => staticState.checkedRowIds.has(getRowNodeId(x)));
					return (
						<div className={clx(styles.headContainer, styles.headCheckboxContainer)}>
							<div className={styles.headCheckbox}>
								<Checkbox
									controlSize={theme == "primary" ? "m" : "sm"}
									isChecked={isChecked}
									onChange={handleParentCheckboxChange}
								/>
							</div>
						</div>
					);
				},
				isDisabled: getCheckboxIsDisabled,
				checkboxInputStyle: getCheckboxInputStyle,
				formatTooltip: () => "",
				getClassName: () => styles.checkboxColumn,
				formatValue: (_value, params) => {
					return (
						<Checkbox
							controlSize={theme == "primary" ? "m" : "sm"}
							inputStyle={params.column.checkboxInputStyle?.(params.rowData)}
							isDisabled={params.column.isDisabled?.(params.rowData)}
							isChecked={staticState.checkedRowIds.has(getRowNodeId(params.rowData))}
							onChange={(isChecked) => {
								const id = getRowNodeId(params.rowData);
								if (isChecked) {
									staticState.checkedRowIds.add(id);
								} else {
									staticState.checkedRowIds.delete(id);
								}
								_onSelectionChange();
							}}
						/>
					);
				},
			});
		}
		if (!_.find(newColumns, (x) => !!x.fullWidth)) {
			newColumns.push({
				id: "__last_column",
				width: 0,
				fullWidth: true,
				headerRenderer: () => "",
			});
		}

		const nonStickyColumns = newColumns.filter((column) => !column.isSticky);
		const stickyColumns = newColumns.filter((column) => column.isSticky);

		newColumns = [...nonStickyColumns, ...stickyColumns];
		return newColumns;
	})();

	rowsData = (() => {
		if (state.sort.columnId == "") return rowsData;
		const column = customColumns.find((x) => x.id == state.sort.columnId);
		if (!column) return rowsData;
		const sortedData = rowsData.slice().sort((a, b) => {
			const valueA = column.valueGetter?.({ rowData: a, column }) ?? a[column.accessor as keyof T];
			const valueB = column.valueGetter?.({ rowData: b, column }) ?? b[column.accessor as keyof T];
			if (state.sort.direction == "asc") {
				if (valueA > valueB) return 1;
				if (valueA < valueB) return -1;
				return 0;
			} else {
				if (valueA < valueB) return 1;
				if (valueA > valueB) return -1;
				return 0;
			}
		});
		return sortedData;
	})();

	const localOnRowDataValueChange = (params: IRowDataValueChangeProps<T>) => {
		setState((x) => {
			const newChangesMap = new Map(x.changesMap);
			const changeMapId = `row:${params.rowId}-col:${params.columnId}`;
			if (params.value == params.originalValue) {
				newChangesMap.delete(changeMapId);
			} else {
				newChangesMap.set(changeMapId, params);
			}
			onChangeMapChange?.(newChangesMap);
			onRowDataValueChange?.(params);
			return {
				...x,
				changesMap: newChangesMap,
			};
		});
	};

	const tableRef = useRef<HTMLTableElement>(null);

	// useEffect(() => {
	// 	const handleResize = () => {
	// 		const tableEl = tableRef.current;
	// 		if (!tableEl) return;
	// 		const filterCustomColumns = customColumns.filter((x) => !x.fullWidth && !x.isSticky && x.id != "__checkbox_column");
	// 		let totalWidth = _.sumBy(filterCustomColumns, (x) => x.width);
	// 		const newWidths = new Map<
	// 			string,
	// 			{
	// 				percentage: number;
	// 				width: number;
	// 			}
	// 		>();
	// 		filterCustomColumns.forEach((column) => {
	// 			newWidths.set(column.id, {
	// 				percentage: column.width / totalWidth,
	// 				width: column.width,
	// 			});
	// 		});
	// 		let fullWidthColumnWidth = 0;
	// 		tableEl.querySelectorAll("thead tr td.fullWidth").forEach((td) => {
	// 			fullWidthColumnWidth = td.clientWidth > 16 ? td.clientWidth : fullWidthColumnWidth;
	// 		});
	// 		filterCustomColumns.forEach((column) => {
	// 			const newWidth = newWidths.get(column.id);
	// 			if (!newWidth) return;
	// 			newWidth.width = newWidth.width - 10 + fullWidthColumnWidth * newWidth.percentage;
	// 			newWidths.set(column.id, newWidth);
	// 		});
	// 		setState((x) => {
	// 			const newColumnSizes = new Map(x.columnSizes);
	// 			newWidths.forEach((value, key) => {
	// 				newColumnSizes.set(key, value.width);
	// 			});
	// 			return {
	// 				...x,
	// 				columnSizes: newColumnSizes,
	// 			};
	// 		});
	// 	};

	// 	window.addEventListener("resize", _.debounce(handleResize, 100));
	// 	return () => {
	// 		window.removeEventListener("resize", handleResize);
	// 	};
	// }, [tableRef.current]);
	const handleTDClick = (
		column: IColumn<T> & {
			width?: number;
		},
	) => {
		if (!column.sortable) return;
		let newSort: ISort = {
			columnId: column.id,
			direction: "asc",
		};
		if (state.sort.columnId == column.id) {
			if (state.sort.direction == "asc") {
				newSort = {
					columnId: column.id,
					direction: "desc",
				};
			} else {
				newSort = {
					columnId: "",
					direction: "asc",
				};
			}
		}

		setState((x) => ({
			...x,
			sort: newSort,
		}));
	};
	return (
		<div className={styles.tableWrapper} style={wrapperStyle}>
			<div className={clx(styles.tableContainer, styles[`${theme}Theme`])}>
				<TableVirtuoso
					totalCount={rowsData.length}
					components={{
						Table: ({ style, ...props }) => <table ref={tableRef} className={styles.table} {...props} style={style} />,
						TableBody: React.forwardRef(({ style, ...props }, ref) => <tbody style={style} {...props} ref={ref} />),
						TableRow: (props) => {
							const index = props["data-index"];
							const rowData = rowsData[index];
							const isSelected = state.selectedRowId == getRowNodeId(rowData);

							return (
								<tr
									data-selected-has-border={hasSelectedRowBorder && isSelected}
									className={clx({
										[styles.tbody_tr]: true,
										[styles.selected]: isSelected,
										[getRowClassName?.({ rowData }) ?? ""]: true,
									})}
									{...props}
									onClick={() => {
										const id = getRowNodeId(rowData);
										if (state.selectedRowId == id) return;
										if (onRowSelection) {
											setState((x) => ({ ...x, selectedRowId: id }));
											onRowSelection(rowData);
											return;
										}
										if (!onSelectionChange || rowSelection != "single") return;
										setState((x) => ({ ...x, selectedRowId: id }));
										onSelectionChange([rowData]);
									}}
								/>
							);
						},
					}}
					fixedHeaderContent={() => {
						return (
							<tr
								className={clx({
									[styles.thead_tr]: true,
									[getTableHeaderClassName?.() ?? ""]: true,
								})}>
								{customColumns.map((column) => {
									return (
										<td
											key={column.id}
											className={clx({ fullWidth: !!column.fullWidth, [styles.thead_td]: true })}
											onClick={() => handleTDClick(column)}
											style={{
												width: column.width,
												...(column.isSticky
													? {
															position: "sticky",
															right: "0px",
														}
													: {}),
												...(column.fullWidth ? { width: "100%" } : {}),
												...(column.getHeaderStyle?.({ column }) ?? {}),
											}}>
											{column.id != "__checkbox_column" && column.id != "__last_column" && (
												<ResizableBox
													width={column.fullWidth ? Infinity : column.width}
													height={0}
													axis="x"
													resizeHandles={["e"]}
													onResizeStop={(e, data) => {
														setState((x) => {
															return {
																...x,
																columnSizes: x.columnSizes.set(column.id, data.size.width),
															};
														});
													}}
													minConstraints={[column.minWidth ?? 50, 0]}
													maxConstraints={[column.maxWidth ?? Number.MAX_SAFE_INTEGER, 0]}
													handle={<div className={styles.resizeHandle} />}
													style={{
														backgroundColor: "Red",
														height: "0px",
														position: "absolute",
														top: 0,
														display: "flex",
														justifyContent: "end",
														width: "100%",
													}}></ResizableBox>
											)}

											{column.headerRenderer?.({
												column: column,
											}) ?? (
												<div
													className={styles.headContainer}
													style={{
														width: column.width,
														...(column.fullWidth ? { width: "100%" } : {}),
													}}>
													<div className={styles.headContent}>{column.header}</div>
													<div className={styles.headSort}>
														{state.sort.columnId == column.id && (
															<SortAscIcon isDesc={state.sort.direction == "desc"} />
														)}
													</div>
												</div>
											)}
										</td>
									);
								})}
							</tr>
						);
					}}
					itemContent={(index) => {
						const rowData = rowsData[index];
						return customColumns.map((column) => {
							const cellProps = {
								key: column.id,
								theme,
								column,
								rowData,
								rowId: getRowNodeId(rowData),
								changesMap: state.changesMap,
								isSaving,
								disableBorder,
								onRowDataValueChange: localOnRowDataValueChange,
							};
							return <CellRenderer<T> {...cellProps} />;
						});
					}}
				/>
			</div>
			{bottomContainer && (
				<div className={styles.bottomContainer}>
					<div className={styles.pageInfo}>{bottomContainer}</div>
				</div>
			)}
		</div>
	);
};

export const VGrid = forwardRef(VGridInner) as <T extends TExtend>(
	props: IVGridProps<T> & { ref?: React.Ref<VGridRef> },
) => ReturnType<typeof VGridInner>;
