import React, { ComponentProps, CSSProperties, forwardRef } from "react";
import styles from "./SectionContainer.module.scss";

interface ISectionContainerProps extends ComponentProps<"div"> {
	children?: React.ReactNode;
	headline?: React.ReactNode;
	rightContent?: React.ReactNode;
	headlineStyle?: CSSProperties;
	bottomButton?: React.JSX.Element;
	hasBorder?: boolean;
}

export const SectionContainer = forwardRef<HTMLDivElement, ISectionContainerProps>((props, ref) => {
	const { children, headline, rightContent, headlineStyle, bottomButton, hasBorder = false, ...rest } = props;
	return (
		<div ref={ref} data-has-border={hasBorder} className={styles.main} {...rest}>
			<div className={styles.headline} style={headlineStyle}>
				<div className={styles.holder}>{headline}</div>
				<div className={styles.content}>{rightContent}</div>
			</div>
			<div className={styles.children}>{children}</div>
			<div className={styles.bottomButton}>{bottomButton}</div>
		</div>
	);
});
