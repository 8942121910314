import helperStyles from "@src/components/helpers.module.scss";
import { IColumn } from "@src/components/vGrid/VGrid";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import React from "react";

export const columns: Array<IColumn<EventInventoryRecord>> = [
	{
		id: "Section",
		header: "Section",
		accessor: "Section",
		getClassName: () => helperStyles.secondaryText,
		width: 115,
		minWidth: 115,
		sortable: true,
	},
	{
		id: "Row",
		header: "Row",
		accessor: "Row",
		getClassName: () => helperStyles.secondaryText,
		width: 60,
		maxWidth: 60,
		minWidth: 60,
		sortable: true,
	},
	{
		id: "Seat",
		header: "Seat",
		accessor: "Seat_Number",
		getClassName: () => helperStyles.secondaryText,
		width: 68,
		maxWidth: 68,
		minWidth: 68,
		sortable: true,
	},
	// {
	// 	Header: "Qty",
	// 	accessor: "Quantity",
	// 	Cell: ({ row }) => (
	// 		<div className={helperStyles.secondaryText}>{row.original.Quantity}</div>
	// 	),
	// 	maxWidth: 55,
	// 	minWidth: 55,
	// },
	{
		id: "Splits",
		header: "Splits",
		accessor: "Vivid_Splits",
		cellRenderer: ({ rowData }) => {
			const splitValue = rowData.Vivid_Splits;
			let displayValue;

			switch (splitValue) {
				case 2:
					displayValue = "Pairs";
					break;
				case 1:
					displayValue = "Any";
					break;
				case 0:
					displayValue = "None";
					break;
				case -1:
				default:
					displayValue = "Default";
			}

			return <div className={helperStyles.secondaryText}>{displayValue}</div>;
		},
		width: 68,
		maxWidth: 68,
		minWidth: 68,
		sortable: true,
	},
];
