import { CheckIcon } from "@src/assets/general-icons";
import { pricingActions } from "@src/store";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ISavedRules } from "@src/store/pricing/savedRulesSlice";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { Button, ButtonVariant } from "../button/Button";
import { RulesDropdown } from "../dropdown/RulesDropdown";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import styles from "./ChooseRule.module.scss";
import useClickOutside from "@src/hooks/useClickOutside";

interface IChooseRule {
	onRulePick: (data: ISavedRules) => void;
	onRuleSave: (ruleName: string) => void;
}

export const ChooseRule: React.FC<IChooseRule> = ({ onRulePick, onRuleSave }) => {
	const { data, loading, error } = useAppSelector((state) => state.pricing.savedRules);
	const [ruleName, setRuleName] = useState("");
	const [save, setSave] = useState(false);

	const inputContainerRef = useRef<HTMLDivElement | null>(null);

	const dispatch = useAppDispatch();

	const handleRuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setRuleName(e.target.value);

	const onSaveToggle = () => setSave((prev) => !prev);
	const handleRuleSave = () => {
		if (!ruleName) {
			toast.error("Please enter Rule Name");
			return;
		}
		if (ruleName.length < 3 || ruleName.length > 30) {
			toast.error("Rule name needs to be between 3 and 30 characters");
			return;
		}

		onRuleSave(ruleName);
		onSaveToggle();
		setRuleName("");
		mixpanelTrack(MixpanelCategory.RULE_SAVED, { data: ruleName });
	};

	let rightContent = (
		<Button
			disabled={loading}
			isLoading={loading}
			onClick={onSaveToggle}
			controlSize="sm"
			className={styles.button}
			variant={ButtonVariant.Primary}>
			Save Rule
		</Button>
	);

	useClickOutside(inputContainerRef, onSaveToggle);

	if (save) {
		rightContent = (
			<div ref={inputContainerRef} className={styles.saveRuleContainer}>
				<div>
					<input ref={(node) => node?.focus()} value={ruleName} onChange={handleRuleNameChange} placeholder="Rule Name" />
				</div>
				<CheckIcon onClick={handleRuleSave} />
			</div>
		);
	}

	useEffect(() => {
		if (data.length) return;
		dispatch(pricingActions.getSavedRules());
	}, [data.length, dispatch]);

	return (
		<div className={styles.main}>
			<div className={styles.headline}>Choose Rule</div>
			<div className={styles.options}>
				<div style={{ flex: 1 }}>
					<RulesDropdown data={data} error={error} onRulePick={onRulePick} />
				</div>
				<div className={styles.or}>or</div>
				<div className={styles.right}>{rightContent}</div>
			</div>
		</div>
	);
};
